<template>
    <LayoutFull v-if="route.meta.layout == 'LayoutFull'" />
    <LayoutBlank v-if="route.meta.layout == 'LayoutBlank'" />
    <LayoutTarif v-if="route.meta.layout == 'LayoutTarif'" />
</template>

<script setup>
import { useRoute } from "vue-router";
import LayoutFull from "./layout/LayoutFull.vue";
import LayoutBlank from "./layout/LayoutBlank.vue";
import LayoutTarif from "./layout/LayoutTarif.vue";

const route = useRoute();
</script>
