const menu = [
    {
        name: "home",
        icon: "icon-[ic--twotone-home]",
        title: "Beranda",
        access: "auth",
        type: "btn",
    },
    {
        name: "kerjasama",
        icon: "icon-[tabler--file-description]",
        title: "Kerjasama",
        access: "auth",
        type: "btn",
    },
    {
        name: "tarif",
        icon: "icon-[tabler--report-money]",
        title: "Tarif",
        access: "auth",
        type: "btn",
    },
    {
        icon: "icon-[tabler--database]",
        title: "Master Data",
        access: "all",
        type: "dropdown",
        list: [
            {
                name: "users",
                icon: "icon-[tabler--users]",
                title: "Pengguna",
                access: "admin",
            },
        ],
    },
];

export default menu;
