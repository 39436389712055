<template>
    <v-app class="overflow-hidden" id="layout">
        <v-defaults-provider :defaults="defaultProvider">
            <v-main id="main" class="layout-page-content">
                <router-view v-slot="{ Component }">
                    <component :is="Component" />
                </router-view>
                <data class="h-20"></data>
            </v-main>
        </v-defaults-provider>
    </v-app>
</template>

<script setup>
import { asset, replaceAvaByDefault } from "@/mixins";
import ListMenu from "./component/ListMenu.vue";
import menu from "@/menu.js";

import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import NavigationBar from "./NavigationBar.vue";
import BottomAppBar from "./component/BottomAppBar.vue";
import {
    ref,
    onMounted,
    computed,
    provide,
    reactive,
    onBeforeUnmount,
} from "vue";
import { useTheme, useDisplay } from "vuetify";
import ability from "@/plugins/casl/ability";

import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();

import { useRouter } from "vue-router";
const router = useRouter();

const theme = useTheme();
const { xs, lgAndUp, width } = useDisplay();
const isDark = computed(() => {
    let current = theme.current.value;
    return current.dark;
});

function goHome() {
    if (width.value < 1280) {
        drawer.value = false;
        router.push({ name: "home" });
    } else {
        router.push({ name: "home" });
    }
}

// const countMenu = computed(() => {
//     return menu.filter((f) => {
//         return ability.can("read", f.access);
//     }).length;
// });
const isDisplay = computed(() => {
    return xs;
});

provide("$isDark", isDark);

const defaultProvider = reactive({
    VCard: {
        border: isDark,
    },
    VToolbar: {
        border: isDark,
    },
    VDialogFull: {
        fullscreen: xs,
        persistent: true,
        scrollable: true,
    },
});

const drawer = ref(lgAndUp);
const offsetTop = ref(0);

const handleDrawer = () => {
    drawer.value = !drawer.value;
};

const onScroll = () => {
    offsetTop.value = window.top.scrollY;
};

const offline = ref(false);
function handleConnectionChange() {
    offline.value = !navigator.onLine;
}

const banner = ref(false);
provide("$banner", banner);
let deferredPrompt = ref(window._deferredPrompt);
provide("$deferredPrompt", deferredPrompt);

async function installApp() {
    banner.value = false;
    if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === "accepted") {
            deferredPrompt.value = null;
        } else {
            deferredPrompt.value = window._deferredPrompt;
        }
    }
}

function installLater() {
    localStorage.setItem("installLater", JSON.stringify(true));
    banner.value = false;
}

onMounted(() => {
    window.addEventListener("scroll", onScroll);
    window.addEventListener("offline", handleConnectionChange);
    window.addEventListener("online", handleConnectionChange);
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
    window.removeEventListener("offline", handleConnectionChange);
    window.removeEventListener("online", handleConnectionChange);
});

// app.config.globalProperties.$offsetTop = offsetTop.value;
</script>

<style scoped>
/* .layout-page-content {
    inline-size: 100%;
    margin-inline: auto;
    max-inline-size: 1400px;
    width: 100vw;
} */

.layout-page-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

.footer {
    position: absolute;
    width: 100%;
    bottom: 0px;
    height: fit-content;
}

.footer .content {
    /* height: 300px; */
    padding-left: 7%;
    padding-right: 7%;
}

.accelerate {
    transform: translateZ(0) !important;
    transform: translate3d(0, 0, 0) !important;
    will-change: transform, opacity;
}
</style>
