import ability from "@/plugins/casl/ability";

export default [
    {
        path: "/not-authorized",
        name: "not-authorized",
        component: () => import("@/pages/error/NotAuthorized.vue"),
        meta: {
            pageTitle: "Tidak memiliki izin",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/404-not-found",
        name: "notFound",
        component: () => import("@/pages/error/Error.vue"),
        meta: {
            pageTitle: "Not found",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/pages/auth/Login.vue"),
        meta: {
            pageTitle: "Login",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/link",
        name: "link",
        component: () => import("@/pages/link/Link.vue"),
        meta: {
            pageTitle: "Link",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/form-kerjasama",
        name: "formKerjasama",
        component: () => import("@/pages/link/FormKerjasama.vue"),
        meta: {
            pageTitle: "Formulir Kerjasama",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/tarif",
        name: "tarif",
        component: () => import("@/pages/tarif/Tarif.vue"),
        meta: {
            pageTitle: "Tarif",
            layout: ability.can("read", "auth") ? "LayoutFull" : "LayoutTarif",
            resource: "guest",
            action: "read",
        },
        children: [
            {
                path: "laboratorium",
                name: "tarifLab",
                component: () => import("@/pages/tarif/TarifLaboratorium.vue"),
                meta: {
                    resource: "guest",
                    action: "read",
                    savedPosition: true,
                },
            },
            {
                path: "radiologi",
                name: "tarifRadiologi",
                component: () => import("@/pages/tarif/TarifRadiologi.vue"),
                meta: {
                    resource: "guest",
                    action: "read",
                    savedPosition: true,
                },
            },
        ],
    },
    // {
    //     path: "/register",
    //     name: "register",
    //     component: () => import("@/pages/auth/Register.vue"),
    //     meta: {
    //         pageTitle: "Registrasi",
    //         layout: "LayoutBlank",
    //         resource: "guest",
    //         action: "read",
    //     },
    // },
    // {
    //     path: "/forgot-password",
    //     name: "forgotPassword",
    //     component: () => import("@/pages/auth/ForgotPassword.vue"),
    //     meta: {
    //         pageTitle: "Lupa password",
    //         layout: "LayoutBlank",
    //         resource: "guest",
    //         action: "read",
    //     },
    // },
    // {
    //     path: "/reset-password",
    //     name: "resetPassword",
    //     component: () => import("@/pages/auth/ResetPassword.vue"),
    //     meta: {
    //         pageTitle: "Reset password",
    //         layout: "LayoutBlank",
    //         resource: "guest",
    //         action: "read",
    //     },
    // },
];
